import "../styles/index.scss";

import * as React from "react";

import AboutUs from "../components/molecules/AboutUs";
import Collage from "../components/molecules/Collage";
import { DataProvider } from "../context/data.context";
import Footer from "../components/molecules/Footer";
import Header from "../components/molecules/Header";
import NewsUpdates from "../components/molecules/NewsUpdates";
import OurBrands from "../components/molecules/OurBrands";
import Partners from "../components/molecules/Partners";
import SEO from "../components/atoms/Seo";
import { graphql } from "gatsby";

const IndexPage = (props) => {
  return (
    <DataProvider data={props.data} locale="en">
      <SEO />
      <Header />
      <main>
        <NewsUpdates />
        <AboutUs />
        <OurBrands />
        <Collage />
        <Partners />
      </main>
      <Footer />
    </DataProvider>
  );
};

export const query = graphql`
  query {
    strapiFedcomSeo(locale: { eq: "en" }) {
      title
      description
    }

    strapiFedcomFooter(locale: { eq: "en" }) {
      back_to_top_text
    }

    strapiFedcomContactUs(locale: { eq: "en" }) {
      email_address
      email_subject
      link_text
    }

    strapiFedcomNewsUpdate(locale: { eq: "en" }) {
      content_heading
      content_2
      content
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: BLURRED)
          }
        }
      }
      section_heading
    }

    strapiFedcomAboutUs(locale: { eq: "en" }) {
      section_heading
      content
      content_heading
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1399, placeholder: BLURRED)
          }
        }
      }
    }

    strapiFedcomOurBrand(locale: { eq: "en" }) {
      content
      cta
      cta_url
      section_heading
      collage_background {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1279, placeholder: BLURRED)
          }
        }
      }
      collage_img_1 {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 627, placeholder: BLURRED)
          }
        }
      }
      collage_img_2 {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 783, placeholder: BLURRED)
          }
        }
      }
      collage_background_mobile {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 342, placeholder: BLURRED)
          }
        }
      }
    }

    strapiFedcomPartner(locale: { eq: "en" }) {
      content_heading
      section_heading
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 251, placeholder: BLURRED)
          }
        }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 631, placeholder: BLURRED)
          }
        }
      }
    }
    allStrapiFedcomPartnerContent(filter: { locale: { eq: "en" } }) {
      nodes {
        content
      }
    }
  }
`;

export default IndexPage;
